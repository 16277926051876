import React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { PageProps, graphql } from 'gatsby';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import { IconTypes } from '../../../components/Icon/Icon';
import { useLocaleContext } from '../../../context/Locale';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../config/RouteKeys';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';
import Link from '../../../components/Link/Link';
import CommonData from '../../../data/common/common';

type Props = PageProps & WithTranslation;

const Internship: React.FC<Props> = (props: Props) => {
  const { t, data } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('internship.seo.title'),
    },
    // page: {
    //   title: t('internship.heading'),
    // },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-careers-internship.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const imageUrls = (data as any).allFile.edges.reduce((accum: any, edge: any) => {
    return { ...accum, [edge.node.name]: edge.node.publicURL };
  }, {});

  return (
    <Layout options={layoutOptions} className="Careers">
      <h1 className="text-center">{t('internship.heading')}</h1>
      <div className="grid-container">
        <div className="row row-wrap row-center">
          <section className="column column-80">
            <div className="Careers__generic-gap">
              <p>
                <Trans t={t} i18nKey="internship.body" />
              </p>
            </div>

            <div className="row row-wrap row-start">
              {(t('internship.internsList', { returnObjects: true }) as {
                imageName: string;
                path: string;
                description: string;
                buttonLabel: string;
                imageAlt: string;
              }[]).map(({ imageName, path, description, buttonLabel, imageAlt }) => {
                const link = getPathFromRouteKey(
                  path.split('.').reduce((accum: any, next: any) => accum[next], RouteKeys)
                );

                return (
                  <div className="column column-40 Careers__generic-gap" key={imageName}>
                    <div className="img-container">
                      <Link to={link}>
                        <img src={imageUrls[imageName]} alt={imageAlt} className="rounded" />
                      </Link>
                    </div>
                    <p className="h6">
                      <Trans>{description}</Trans>
                    </p>
                    <ButtonLink
                      to={link}
                      iconOptions={{
                        icon: IconTypes.ARROW_NEXT,
                      }}
                      styleOptions={{ isInline: true }}
                      linkOptions={{ shouldEnableActiveState: false }}
                    >
                      {buttonLabel}
                    </ButtonLink>
                  </div>
                );
              })}
            </div>

            <div className="Careers__generic-gap">
              <p>
                <Trans t={t} i18nKey="internship.bodyTwo">
                  <ExternalLink href={CommonData.websites.htb.linkedin} />
                </Trans>
              </p>
              <ButtonLink
                to={getPathFromRouteKey(RouteKeys.CAREERS)}
                iconOptions={{
                  icon: IconTypes.ARROW_NEXT,
                }}
                styleOptions={{ isInline: true }}
                linkOptions={{ shouldEnableActiveState: false }}
              >
                {t('heading')}
              </ButtonLink>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/careers/internship//" }, ext: { eq: ".jpg" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.CAREERS)(Internship);
